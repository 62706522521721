<template>
  <v-container fluid class="z-position py-0">
    <v-row no-gutters>
      <v-col cols="auto" class="category">
        <vue-markdown :source="title" :postrender="convertText" />
        <template v-if="shouldShowSeeMoreButton">
          <template v-if="isFullUrl">
            <a :href="ctaResourceUrl" class="category-link">
              {{ getCtaTextOrCommonLocalizedText }}
            </a>
          </template>
          <template v-else>
            <router-link :to="ctaResourceUrl" class="category-link">
              {{ getCtaTextOrCommonLocalizedText }}
            </router-link>
          </template>
        </template>
      </v-col>
    </v-row>

    <v-row class="mb-0">
      <v-col cols="12" class="pa-0">
        <CarouselComponent
          :ctaPath="ctaPath"
          :ctaText="ctaText"
          :items="items"
          :displayPage="displayPage"
          :title="title"
          :displayType="displayType"
          :hookType="hookType"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CarouselComponent from './CarouselComponent';
import ConciergeHomeMixins from '@/modules/concierge/mixins/ConciergeHomeMixins';

export default {
  name: 'CategoryComponent',
  components: { CarouselComponent },
  mixins: [ConciergeHomeMixins],
  props: {
    displayPage: String,
    title: String,
    displayType: {
      type: String,
    },
    hookType: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    showSeeAllBtn: {
      type: Boolean,
      default: false,
    },
    ctaPath: String,
    ctaText: String,
  },

  methods: {
    convertText(text) {
      return text
        .replace(/p>/g, 'span>')
        .replace('<span>', '<span class="text-item mr-5">');
    },
  },

  computed: {
    ctaResourceUrl() {
      return this.shouldShowSeeMoreButton
        ? this.makeCTAPathResourceUrl(this.ctaPath)
        : '';
    },

    isFullUrl() {
      return (
        this.shouldShowSeeMoreButton && !this.ctaResourceUrl.startsWith('/')
      );
    },

    shouldShowSeeMoreButton() {
      return Boolean(this.ctaPath);
    },

    getCtaTextOrCommonLocalizedText() {
      if (!!this.ctaText) {
        return this.ctaText;
      } else return this.$t('common.see_more');
    },
  },
};
</script>

<style lang="scss" scoped>
.z-position {
  z-index: 1;
}

.category {
  display: flex;
  align-items: center;

  @media (max-width: map-get($grid-breakpoints, sm)) {
    flex-wrap: wrap;
  }

  &-link {
    color: var(--v-primary-base);
    @include font-size(16, 187.5);
    text-decoration: none;
    white-space: nowrap;
  }
}

::v-deep .text-item {
  @include font-size(20, 120, 500);

  @media (min-width: map-get($grid-breakpoints, sm)) {
    @include font-size(24, 150, 500);
  }
}
</style>
