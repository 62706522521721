<template>
  <v-container
    :class="['pa-0', { 'overflow-hidden': $vuetify.breakpoint.mdAndUp }]"
  >
    <v-row
      data-cy="featured-multiple-item-item-row"
      no-gutters
      :class="[
        'item-card pa-0 pa-sm-10 ma-0 flex-column justify-space-between',
        rowJustify === 'end' ? 'flex-md-row' : 'flex-md-row-reverse',
      ]"
    >
      <v-col
        cols="12"
        md="6"
        :class="[rowJustify === 'start' ? 'pl-0 pl-md-10' : 'pr-0 pr-md-10']"
      >
        <vue-markdown
          class="featured-item-title"
          data-cy="featured-multiple-item-item-title"
          :source="title"
          :postrender="convertFeaturedItemTitle"
        />

        <template v-if="subtitle">
          <vue-markdown
            :class="[ctaPath ? 'mb-0' : 'mb-3']"
            data-cy="featured-multiple-item-item-subtitle"
            :source="subtitle"
          />
        </template>

        <template v-if="ctaPath">
          <v-btn
            depressed
            color="primary"
            :href="makeResourceUrl(ctaPath)"
            :class="[
              'view-more-text',
              $vuetify.breakpoint.mdAndDown ? 'mt-1 mb-6' : 'mt-5',
            ]"
            data-cy="featured-multiple-item-item-call-to-action"
          >
            {{ getCtaTextOrCommonLocalizedText }}
          </v-btn>
        </template>
      </v-col>

      <v-col
        data-cy="featured-multiple-item-item-wrapper"
        cols="12"
        md="6"
        class="item-info-wrapper mx-n3 mx-sm-0"
      >
        <carousel
          data-cy="carousel-component"
          class="item-info-container"
          :scrollPerPage="$vuetify.breakpoint.lgAndUp"
          :perPage="carouselSlideSize"
          :navigationEnabled="$vuetify.breakpoint.lgAndUp"
          :paginationEnabled="false"
          :touchDrag="$vuetify.breakpoint.smAndDown"
          :mouseDrag="$vuetify.breakpoint.smAndDown"
          :navigationNextLabel="navigationNext"
          :navigationPrevLabel="navigationPrev"
        >
          <slide
            data-cy="featured-multiple-item-item-info"
            v-for="item in items"
            :key="item.title"
            class="slide py-0 pl-3 pl-md-0"
          >
            <ProductItemContainer
              :item="item"
              :hookItemType="hookType"
              :aspectRatio="16 / 9"
              :height="192"
            />
          </slide>
        </carousel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import ConciergeHomeMixins from '@/modules/concierge/mixins/ConciergeHomeMixins.vue';
import ProductItemContainer from '@/modules/concierge-v3/features/explore-list/ProductItemContainer';

export default {
  name: 'ConciergeFeaturedMultipleItem',
  components: { ProductItemContainer, Carousel, Slide },
  mixins: [ConciergeHomeMixins],
  props: {
    title: String,
    subtitle: String,
    rowJustify: String,
    items: Array,
    ctaPath: String,
    ctaText: String,
    hookType: String,
  },

  data() {
    return {
      navigationNext:
        '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle id="circle1" opacity="0.8" cx="24" cy="24" r="24" fill="white"/><path d="M23 30L29 24L23 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      navigationPrev:
        '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle id="circle1" opacity="0.8" cx="24" cy="24" r="24" fill="white"/><path d="M25 30L19 24L25 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    };
  },

  computed: {
    getCtaTextOrCommonLocalizedText() {
      if (!!this.ctaText) {
        return this.ctaText;
      } else return this.$t('common.see_more');
    },

    isCarouselCatalogType() {
      return this.displayType === 'carousel';
    },

    carouselSlideSize() {
      return this.isCarouselCatalogType
        ? this.carouselSlideSizeForItem
        : this.carouselSlideSizeForWebOrQueryItem;
    },

    carouselSlideSizeForWebOrQueryItem() {
      let pageSize = 2;
      if (this.$vuetify.breakpoint.smAndDown) {
        pageSize = 1.1;
      }
      return this.items.length >= pageSize ? pageSize : 3;
    },

    carouselSlideSizeForItem() {
      let pageSize = 3;
      if (this.$vuetify.breakpoint.mdAndDown) {
        pageSize = 2;
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        pageSize = 1.1;
      }
      return pageSize;
    },
  },
};
</script>

<style scoped lang="scss">
.item {
  &-card {
    @media (min-width: map-get($grid-breakpoints, md)) {
      background-color: var(--v-grey1-base);
      border-radius: 8px;
    }
  }

  &-info a {
    text-decoration: none;
  }

  &-info-wrapper {
    width: 100vw;
    max-width: 100vw;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      background: transparent;
      width: 0;
      height: 0;
    }

    @media (min-width: map-get($grid-breakpoints, sm)) {
      overflow-x: unset;
      max-width: unset;
      width: unset;
    }
  }

  &-info-container {
    flex-direction: row;
    max-width: 100vw;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      max-width: unset;
    }
  }
}

.view-more-text {
  border-radius: 4px !important;
  padding: 9px 12px !important;
  display: inline-block;
  @include font-size(14, 150, 600);
}

@media (min-width: map-get($grid-breakpoints, sm)) {
  ::v-deep .VueCarousel-inner {
    justify-content: space-between;
  }
  .slide {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    max-width: calc(50% - 12px);
  }
  .slide:first-child {
    padding-left: 0 !important;
  }
  .slide:last-child {
    padding-right: 0 !important;
  }
}
</style>
