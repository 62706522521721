<template>
  <v-card width="375">
    <v-card-text class="pa-4">
      <v-container class="pa-0 add-flight-container">
        <p class="add-flight-title">
          {{ $t('flights.add_flight') }}
        </p>
        <v-form
          ref="form"
          @submit.prevent="handleSubmit"
          role="form"
          lazy-validation
        >
          <v-row
            no-gutters
            justify="space-between"
            align="end"
            class="flex-row"
          >
            <v-col id="flightNumber">
              <p class="mb-2 flight-number-title">
                {{ $t('flights.flight_no') }}
              </p>
              <v-text-field
                autofocus
                outlined
                dense
                required
                :rules="requiredFieldRules"
                :placeholder="loadAirlineExampleFlight"
                v-model.trim="flightNumber"
                hide-details
                height="44px"
                class="flight-number data-hj-allow"
                tabindex="0"
                label=""
                aria-labelledby="flightNumber"
                aria-selected="true"
                @keydown="resetSearchResults"
              />
            </v-col>
            <v-col class="px-2">
              <DateTimeSelector
                inputHeight="44px"
                @input="onDateTimeChange"
                :timeTab="false"
                :allowPastDates="false"
                :dateTitle="$t('flights.departure_date')"
                :value="dateTime"
                :isHideDetails="true"
                :inputPlaceholder="$t('select_date')"
                :hasTitle="true"
                :allowTimeSelection="false"
              />
            </v-col>
            <div class="text-right">
              <v-btn
                tabindex="0"
                class="search-btn"
                block
                large
                depressed
                type="submit"
                color="primary"
                height="44px"
                width="82px"
                :loading="fligtsSearchLoading"
              >
                {{ $t('search') }}
              </v-btn>
            </div>
          </v-row>

          <template v-if="fligtsSearchError">
            <p class="no-results ma-0">
              {{ flightSearchResultsError || fligtsSearchError }}
            </p>
          </template>
          <template
            v-else-if="
              !fligtsSearchLoading &&
                fligtsSearchResults &&
                Object.keys(fligtsSearchResults).length === 0
            "
          >
            <p class="no-results ma-0">
              {{ $t('flights.no_flights') }}
            </p>
          </template>

          <template v-else>
            <FlightListItem
              v-for="(flight, index) in fligtsSearchResults"
              :key="index"
              @submitFlight="onAddClick"
              :flight="flight"
              :divider="index !== fligtsSearchResults.length - 1"
              class="ma-1"
            />
          </template>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import DateTimeSelector from '@/core/components/DateTimeSelector';
import moment from 'moment';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import FlightsSearchMixins from '@/modules/flights/mixins/FlightsSearchMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import FlightListItem from '@/modules/flights/features/FlightsList/FlightListItem';
import FlightsFFMixins from '@/modules/flights/mixins/FlightsFFMixins';
import HotJarMixins from '@/core/mixins/HotJarMixins';

export default {
  name: 'AddFlightConciergeContainer',
  mixins: [
    FlightsMixins,
    FlightsSearchMixins,
    GoogleTagManagerMixins,
    FlightsFFMixins,
    HotJarMixins,
  ],
  components: {
    FlightListItem,
    DateTimeSelector,
  },

  data() {
    return {
      flightNumber: null,
      dateTime: null,
    };
  },

  created() {
    this.clearFlights();
  },

  computed: {
    loadAirlineExampleFlight() {
      let placeholderFlight =
        this.$store.state.configs && this.$store.state.configs['flight_trigger']
          ? this.$store.state.configs['flight_trigger'].placeholder_flight
          : null;
      return !!placeholderFlight ? placeholderFlight : 'A10999';
    },

    searchParams() {
      return {
        flightNumber: this.flightNumber,
        departureDate: this.datetime || null,
      };
    },

    requiredFieldRules() {
      return [(v) => !!v || this.$t('required')];
    },
  },

  watch: {
    flightSearchResultsError(val) {
      if (!!val) {
        this.pushFailedSearchFlight(val, {
          flight_number: this.flightNumber,
          departure_date: this.dateTime,
        });
      }
    },
  },

  methods: {
    handleSubmit() {
      if (this.$refs.form.validate()) {
        const flightAnalytics = {
          flight_number: this.flightNumber.toUpperCase(),
          departure_date: this.dateTime,
        };
        this.pushSearchFlight(flightAnalytics);
        this.pushHotJarAnalytics('search_flight');
        this.flightNumber = this.flightNumber.replace(/ /g, '');
        this.searchForFlight({
          flightNumber: this.flightNumber.toUpperCase(),
          departureDate: this.dateTime,
          withFlightPreorderAvailability: this.showFlightPreOrder,
          searchWithCodeShareFlightNumber: this.searchFlightByCodeShareNumber,
        });
      } else if (this.fligtsSearchError) {
        this.clearFlights();
      }
    },

    onAddClick(flight) {
      this.pushSelectFlight(flight);
      this.pushHotJarAnalytics('select_flight');
      if (this.$route.query && this.$route.query.addFlightModal) {
        let query = Object.assign({}, this.$route.query);
        delete query['addFlightModal'];
        this.$router.replace({ query }).catch((err) => {});
      }
      this.addFlightsToContext([flight]);
      this.setSelectedFlight(flight);
    },

    onDateTimeChange(dateTime) {
      this.resetSearchResults();
      if (!!dateTime) {
        this.dateTime = moment(dateTime).format('YYYY-MM-DD HH:mm');
      }
    },

    resetSearchResults() {
      this.fligtsSearchError = false;
      this.fligtsSearchLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.add-flight {
  &-container {
    color: var(--v-grey8-base);
  }

  &-title {
    color: black;
    display: flex;
    align-items: center;
    @include font-size(18, 27, 600);
  }
}

.search-btn {
  font-weight: 600;
}

::v-deep {
  & .subtitle-label {
    @include font-size(16, 150, 600);
    margin-bottom: 5px;
    display: block;
  }

  & .v-input fieldset {
    border: 1px solid #cfd5e0;
    border-radius: 8px;
  }

  & .flight-number .v-input__slot {
    padding: 0 10px !important;
  }
}

.no-results {
  @include font-size(16, 26, 600);
  text-align: center;
  color: var(--v-grey8-base);
  padding: 27px 0;
}

.flight-number-title {
  @include font-size(14, 21, 600);
}
</style>
