<template>
  <v-container
    class="pb-0 pt-5 pl-3 pr-0 pr-sm-3"
    fluid
    data-cy="carousel-component-container"
  >
    <carousel
      v-model="currentSlide"
      data-cy="carousel-component"
      class="mx-0"
      :perPageCustom="carouselSlideSize"
      :navigationEnabled="$vuetify.breakpoint.mdAndUp"
      :paginationEnabled="false"
      :touchDrag="true"
      :mouseDrag="true"
      :navigationNextLabel="navigationNext"
      :navigationPrevLabel="navigationPrev"
      :navigateTo="navigateTo"
    >
      <slide
        v-for="(item, index) in items"
        :key="index"
        data-cy="category-item"
        class="slide pa-1 pa-md-3"
        @slide-click="onCarouselImageClick()"
      >
        <ProductItemContainer
          :item="item"
          :aspectRatio="4 / 3"
          :height="productImageHeight"
          :hookItemType="hookType"
          @click="onProductClick(item)"
        />
      </slide>
    </carousel>
  </v-container>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import ProductItemContainer from './ProductItemContainer';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import ConciergeHomeMixins from '@/modules/concierge/mixins/ConciergeHomeMixins';
import HotJarMixins from '@/core/mixins/HotJarMixins';

export default {
  components: {
    Carousel,
    Slide,
    ProductItemContainer,
  },

  mixins: [GoogleTagManagerMixins, HotJarMixins, ConciergeHomeMixins],

  props: {
    displayPage: String,
    title: String,
    ctaPath: String,

    displayType: {
      type: String,
    },

    hookType: {
      type: String,
    },

    items: {
      type: Array,
      default: () => [],
    },

    aspectRatio: {
      type: Number || String,
      default: 1.5,
    },

    maxHeight: {
      type: Number,
      default: 220,
    },

    ctaText: String,
  },

  data() {
    return {
      currentSlide: 0,
      navigationNext:
        '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle id="circle1" opacity="0.8" cx="24" cy="24" r="24" fill="white"/><path d="M23 30L29 24L23 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      navigationPrev:
        '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle id="circle1" opacity="0.8" cx="24" cy="24" r="24" fill="white"/><path d="M25 30L19 24L25 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    };
  },

  methods: {
    onProductClick(product) {
      this.pushExperiencesItemClickAnalytics(
        product,
        this.title,
        'list',
        this.displayPage
      );
      this.pushHotJarAnalytics('experiences_item_click');
    },

    onCarouselImageClick() {
      localStorage.setItem(this.keyLocalStorage, this.currentSlide);
    },
  },

  computed: {
    keyLocalStorage() {
      return `onCarouselCurrentPositionFor ${this.title}`;
    },

    clickedCarouselIndex() {
      const currentPosition =
        Number(localStorage.getItem(this.keyLocalStorage)) || 0;
      localStorage.removeItem(this.keyLocalStorage);
      return currentPosition;
    },

    scrollPerPage() {
      return this.$vuetify.breakpoint.lgAndUp;
    },

    navigateTo() {
      return this.clickedCarouselIndex;
    },

    productImageHeight() {
      let imageHeight = 251;

      if (this.$vuetify.breakpoint.lg) {
        imageHeight = 198;
      }

      if (this.$vuetify.breakpoint.sm) {
        imageHeight = 215;
      }

      if (this.$vuetify.breakpoint.xs) {
        imageHeight = 126;
      }

      return imageHeight;
    },

    isCarouselCatalogType() {
      return this.displayType === 'carousel';
    },

    carouselSlideSize() {
      return this.isCarouselCatalogType
        ? this.carouselSlideSizeForItem
        : this.carouselSlideSizeForWebOrQueryItem;
    },

    carouselSlideSizeForWebOrQueryItem() {
      return [
        [0, 1],
        [600, 3],
      ];
    },

    carouselSlideSizeForItem() {
      return [
        [0, 2],
        [600, 3],
        [1264, 4],
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.slide {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.slide:first-child {
  padding-left: 0 !important;
}
.slide:last-child {
  padding-right: 0 !important;
}
.link {
  text-decoration: none;
}
::v-deep {
  .VueCarousel {
    position: relative;

    &-wrapper {
      overflow: visible;

      @media (min-width: map-get($grid-breakpoints, md)) {
        overflow: hidden;
      }
    }
  }
  .VueCarousel-navigation-button {
    top: 100px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      top: 135px;
    }
  }
  .VueCarousel-navigation-button:focus {
    outline: none;
  }
  .VueCarousel-navigation-next {
    transform: translateY(-100%) translateX(-30%) !important;
  }
  .VueCarousel-navigation-prev {
    transform: translateY(-100%) translateX(30%) !important;
  }
  #circle1:hover {
    cursor: pointer;
    opacity: 1.5;
  }
}
</style>
