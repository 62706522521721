<template>
  <v-container class="pa-0">
    <template v-if="conciergeHookContent.isLoading">
      <v-row
        class="mx-auto"
        v-for="rowIndex in 3"
        :key="rowIndex + 10"
        no-gutters
      >
        <v-col v-for="colIndex in skeletonLoaderSize" :key="colIndex + 20">
          <v-skeleton-loader type="card" :loading="true" :tile="false" />
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row
        :id="`slideItem${index}`"
        no-gutters
        v-for="(catalog, index) in featuredSideItems"
        :key="index"
      >
        <v-col cols="12" class="mt-8 mt-md-8">
          <template
            v-if="$vuetify.breakpoint.smAndDown && catalog.items.length > 0"
          >
            <v-divider class="mx-3 mb-8" />
          </template>

          <CategoryGroupContainer
            :catalog="catalog"
            displayPage="Concierge Page"
            showSeeAllBtn
          />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import CategoryGroupContainer from './CategoryGroupContainer';
import ConciergeHomeMixins from '@/modules/concierge/mixins/ConciergeHomeMixins';

export default {
  name: 'ExploreListComponent',
  mixins: [ConciergeHomeMixins, GoogleTagManagerMixins],
  components: {
    CategoryGroupContainer,
  },

  computed: {
    noneEmptyGroups() {
      return this.conciergeHooks.filter(
        (hook) => (hook.items && hook.items.length) || this.noItemsHook(hook)
      );
    },

    featuredSideItems() {
      if (this.noneEmptyGroups && this.noneEmptyGroups.length) {
        return this.noneEmptyGroups.map((item, index) => {
          if (item.display_type.includes('feature')) {
            item.rowJustify = index % 2 === 1 ? 'start' : 'end';
          }
          return item;
        });
      } else {
        return [];
      }
    },
  },

  methods: {
    noItemsHook(hook) {
      return hook.display_type === 'divider' || hook.display_type === 'banner';
    },
  },

  watch: {
    displayedCatalogs(val) {
      if (val && !!val.length) {
        this.pushProductImpressions(val);
      }
    },
  },
};
</script>
