<template>
  <button
    data-cy="explore-bellow-button"
    class="explore"
    @click="$vuetify.goTo('#slideItem0', options)"
  >
    {{ text }}
    <svg
      data-cy="explore-bellow-button-chevron"
      class="explore-chevron"
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 0.875977L4.18182 3.68965L7.36364 0.875977"
        stroke="#2F2F2F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 4.25293L4.18182 7.0666L7.36364 4.25293"
        stroke="#2F2F2F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  name: 'ExploreBelowButton',

  data() {
    return {
      options: {
        duration: 0,
        offset: 0,
        easing: 'linear',
      },
    };
  },
};
</script>

<style scoped lang="scss">
.explore {
  background: transparent;
  position: relative;
  @include font-size(14, 150, 500);

  @media (min-width: map-get($grid-breakpoints, sm)) {
    @include font-size(16, 150, 500);
  }

  &-chevron {
    position: absolute;
    right: -20px;
    animation: jumpInfinite 1.2s infinite;
  }
}

@keyframes jumpInfinite {
  0% {
    top: 8px;
  }
  50% {
    top: 12px;
  }
  100% {
    top: 8px;
  }
}
</style>
