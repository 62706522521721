<template>
  <div :class="['title-container', { 'mt-n16': !!displayedHeaderImage }]">
    <template v-if="invalidFlightAlert">
      <ToastComponent @toastClosed="clearInvalidFlightAlert" />
    </template>

    <div class="header-container">
      <template v-if="!!displayedHeaderImage">
        <v-img
          alt="logo"
          cover
          :src="displayedHeaderImage"
          position="center"
          class="header-container-img"
          data-cy="concierge-header-title-image"
        />
      </template>

      <v-container class="pb-sm-1 pb-0">
        <v-row
          :no-gutters="hasDataAndFlightContext"
          :class="[
            'header-container-info pt-5 pt-sm-12 pr-3 mb-0',
            { 'title-text': !!displayedHeaderImage },
          ]"
        >
          <template v-if="hasDataAndFlightContext">
            <v-col cols="12">
              <p
                data-cy="concierge-header-flight-info"
                class="mb-2 text-left concierge-header-flight-info"
              >
                {{ selectedFlightNumber }} •
                {{ headerFlightTitle + '  ' + localeTime }}
              </p>
            </v-col>
          </template>

          <template v-if="!isLoading && headerTitle">
            <v-col
              cols="12"
              sm="10"
              class="pt-0 pb-4 pb-md-6"
              data-cy="concierge-header-title"
            >
              <vue-markdown
                class="hero-title"
                :source="headerTitle"
                :postrender="convertHeroTitleTextV3"
              />
            </v-col>
          </template>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="12"
            class="pa-0 pb-6"
            data-cy="concierge-header-subtitle"
          >
            <template v-if="isLoading">
              <div class="px-10">
                <v-skeleton-loader type="heading" />
                <v-skeleton-loader type="heading" />
              </div>
            </template>

            <template v-else>
              <vue-markdown
                class="hero-subtitle"
                :source="subtitleHtml"
                :postrender="convertHeroSubtitleTextV3"
              />
            </template>
          </v-col>
        </v-row>

        <template v-if="!isLoading && headerCTAText">
          <v-row no-gutters justify="start">
            <ExploreBelowButton :text="headerCTAText" class="pb-10 ml-0" />
          </v-row>
        </template>

        <template v-if="shouldShowFlightSelector && !displayedHeaderImage">
          <v-row
            no-gutters
            justify="start"
            class="text-left py-0 mb-10 mb-md-12 mt-n3"
          >
            <AddFlightConciergeContainer
              data-cy="add-flight-container"
              analytics-origin="page"
            />
          </v-row>
        </template>
      </v-container>

      <template v-if="shouldShowFlightSelector && !!displayedHeaderImage">
        <v-container class="add-flight-container py-0 mb-10 mb-md-12 mt-n3">
          <AddFlightConciergeContainer
            data-cy="add-flight-container"
            analytics-origin="page"
          />
        </v-container>
      </template>
    </div>
  </div>
</template>

<script>
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins.vue';
import ConciergeHomeMixins from '@/modules/concierge/mixins/ConciergeHomeMixins';
import ToastComponent from '@/core/components/ToastComponent.vue';
import ExploreBelowButton from '@/modules/concierge-v3/features/ExploreBelowButton';
import AddFlightConciergeContainer from '@/modules/concierge-v3/AddFlightConciergeContainer';
import LanguageMixins from '@/core/mixins/LanguageMixins';

export default {
  name: 'ConciergeHeaderTitles',
  mixins: [ConciergeHomeMixins, SelectedContextFlightMixins, LanguageMixins],
  components: {
    ToastComponent,
    ExploreBelowButton,
    AddFlightConciergeContainer,
  },

  data() {
    return {
      enableAddFlightFF: false,
    };
  },

  async mounted() {
    await this.checkEnableAddFlightFF();
    this.convertTimeToLocale(this.momentLocale);
  },

  computed: {
    hasDataAndFlightContext() {
      return !this.isLoading && this.localeTime && !!this.selectedContextFlight;
    },

    momentLocale() {
      return this.userPreferredLanguage
        ? this.userPreferredLanguage?.split('-')[0]
        : 'en';
    },

    displayedHeaderImage() {
      return this.$vuetify.breakpoint.mdAndDown && this.headerImageMobile
        ? this.headerImageMobile
        : this.headerImage;
    },

    shouldShowFlightSelector() {
      return (
        this.enableAddFlightFF && !this.isLoading && this.currentStage === 4
      );
    },
  },

  methods: {
    async checkEnableAddFlightFF() {
      this.enableAddFlightFF = await this.$ldclient.variation(
        'enable-add-flight-component',
        false,
        true
      );
      this.$ldclient.$on('change:enable-add-flight-component', (value) => {
        this.enableAddFlightFF = value;
      });
    },
  },

  watch: {
    checkEnableAddFlightFF(val) {
      if (!!val) {
        this.checkEnableAddFlightFF();
      }
    },

    selectedContextFlight(val) {
      if (!!val) {
        this.menu = false;
        this.convertTimeToLocale(this.momentLocale);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header-container {
  position: relative;

  &-img {
    margin-top: 65px;
    height: 240px;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      height: 50vh;
      min-height: 400px;
    }
  }

  &-info {
    &.title-text {
      background: #fff;
      width: 90%;
      margin-top: -50px;
      z-index: 1;
      position: relative;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        width: 85%;
        margin-top: -85px;
      }

      &::before {
        content: '';
        position: absolute;
        background: #fff;
        width: 100%;
        height: 100%;
        left: -95%;
        top: 0;
        z-index: -1;
      }
    }
  }
}

.title-container {
  @media (min-width: map-get($grid-breakpoints, sm)) {
    min-height: 160px;
  }

  ::v-deep {
    & .hero-title .text-item {
      color: var(--v-grey8-base);
      margin-bottom: 0;
      @include font-size(24, 130, 500);

      @media (min-width: map-get($grid-breakpoints, sm)) {
        @include font-size(48, 129, 500);
      }
    }
    & .hero-subtitle .text-item {
      @include font-size(16, 150, 500);

      @media (min-width: map-get($grid-breakpoints, sm)) {
        @include font-size(20, 120, 500);
      }
    }
  }
}

::v-deep .v-skeleton-loader__heading {
  width: 100%;
  margin-bottom: 20px;
}

.concierge-header-flight-info {
  @include font-size(12, 150, 500);

  @media (min-width: map-get($grid-breakpoints, sm)) {
    @include font-size(16, 150, 400);
  }
}
</style>
