<template>
  <v-container class="pa-0" data-cy="product-item-component-container">
    <v-row no-gutters>
      <v-col cols="12">
        <div class="product-image-wrapper">
          <v-img
            data-cy="product-item-component-image"
            class="product-image white--text align-end"
            :alt="`thumbnail for ${title}`"
            :src="thumbnail ? thumbnail : placeholder"
            :height="height ? height : ''"
            :width="width ? width : ''"
            :max-height="240"
            :aspect-ratio="aspectRatio"
          />

          <template v-if="customBadgesSrc">
            <img
              :src="customBadgesSrc"
              alt="product-badge"
              class="product-badge"
              data-cy="item-product-badge"
            />
          </template>
        </div>

        <template v-if="title">
          <h3
            class="truncate title-text size16-weight600 pt-2"
            data-cy="product-item-component-title"
          >
            {{ title }}
          </h3>
        </template>

        <template v-if="(priceValue || priceStartingValue) && priceCurrency">
          <p class="price-text mb-0" data-cy="product-item-component-price">
            <template v-if="isShopOfferingItem">
              <template v-if="priceStartingValue > priceValue">
                {{ $t('common.price_starting_from') }}
                {{ priceStartingValue | currency(priceCurrency) }}
              </template>

              <template v-else>
                {{ priceValue | currency(priceCurrency) }}
              </template>
            </template>

            <template v-else>
              {{ $t('common.from') }}
              {{ priceValue | currency(priceCurrency) }}
              {{ $t('common.per_person') }}
            </template>
          </p>
        </template>

        <template v-if="description && isInformationItem">
          <p
            class="truncate title-text mb-0"
            data-cy="product-item-component-description"
          >
            {{ description }}
          </p>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ProductItemComponent',
  props: {
    hookItemType: {
      type: String,
      default: null,
    },

    title: {
      type: String,
      default: null,
    },

    badges: {
      type: Array,
      default: () => [],
    },

    thumbnail: {
      type: String,
      default: null,
    },

    pointsValue: {
      type: Number,
      default: 0,
    },

    priceValue: {
      type: Number,
      default: 0,
    },

    priceStartingValue: {
      type: Number,
      default: 0,
    },

    priceCurrency: {
      type: String,
      default: null,
    },

    description: {
      type: String,
      default: null,
    },

    aspectRatio: {
      type: Number || String,
      default: 1.5,
    },

    height: {
      type: Number || String,
      default: null,
    },

    width: {
      type: Number || String,
      default: null,
    },
  },

  data() {
    return {
      isModalActive: false,
      placeholder: require('@/assets/placeholder-thumbnail.png'),
    };
  },

  computed: {
    enableLoyalty() {
      return this.$store.getters['featureFlags/enableLoyalty'];
    },

    isBookingItem() {
      return this.hookItemType === 'booking';
    },

    isBookingNearbyItem() {
      return this.hookItemType === 'booking-nearby';
    },

    isHealthItem() {
      return this.hookItemType === 'health';
    },

    isInformationItem() {
      return this.hookItemType === 'information';
    },

    isPlaceItem() {
      return this.hookItemType === 'place';
    },

    isShopOfferingItem() {
      return this.hookItemType === 'shop-offering';
    },

    isShopCategoryItem() {
      return this.hookItemType === 'shop-category';
    },

    isTransportationItem() {
      return this.hookItemType === 'transportation';
    },

    customBadgesSrc() {
      return this.badges?.length > 0 ? this.badges[0]?.svg : null;
    },
  },

  methods: {
    toggleModal() {
      this.isModalActive = !this.isModalActive;
    },
  },
};
</script>

<style lang="scss" scoped>
.product {
  &-image {
    width: 100%;

    &-results {
      height: 150px;
    }

    ::v-deep {
      .v-image__image {
        border-radius: 8px;
      }
    }
  }
}

.title-text {
  color: #000;
}

.price-text {
  color: #000;
  font-size: 14px;
  font-weight: normal;
}

.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.amount {
  font-weight: bold;
}

.product-image-wrapper {
  position: relative;
}

.product-badge {
  position: absolute;
  bottom: 12px;
  right: 12px;
  max-height: 24px;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1));

  &.badge-text {
    padding: 0 10px;
    border-radius: 4px;
    color: #fff;
    @include font-size(12, 165, 400);
    background-color: #db0020;
  }
}
</style>
