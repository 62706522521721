<template>
  <div class="divider-card py-12 px-8 px-sm-12">
    <vue-markdown
      class="text-center"
      :source="title"
      :postrender="convertDivider"
    />
  </div>
</template>

<script>
import ConciergeHomeMixins from '@/modules/concierge/mixins/ConciergeHomeMixins';

export default {
  name: 'ConciergeDividerItem',
  mixins: [ConciergeHomeMixins],
  props: {
    title: String,
  },
};
</script>

<style scoped lang="scss">
.divider {
  &-card {
    width: 100%;
    background-color: var(--v-grey1-base);
    border-radius: unset;
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      width: 100vw;
      height: 100%;
      background: var(--v-grey1-base);
      top: 0;
      left: calc((-100vw + 100%) / 2);
      z-index: -1;
    }
  }
}
</style>
