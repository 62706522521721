<template>
  <v-container class="pa-0">
    <a
      target="_blank"
      :href="makeResourceUrl(itemDetails.resource)"
      @click.stop.prevent="onCardClick"
      class="text-decoration-none"
      style="color: inherit"
    >
      <v-card class="overflow-hidden" min-height="100%" elevation="0">
        <v-row
          :class="[
            'pa-0 ma-0 flex-column-reverse',
            rowJustify === 'end' ? 'flex-md-row' : 'flex-md-row-reverse',
          ]"
        >
          <v-col
            cols="12"
            md="6"
            class="item-details mt-4 mt-md-0 pa-0 pa-md-10"
          >
            <vue-markdown
              class="featured-item-title"
              data-cy="concierge-featured-single-item-title"
              :source="itemDetails.title"
              :postrender="convertFeaturedItemTitle"
            />

            <template v-if="isPriceVisible || isRatingVisible">
              <div
                class="
                  featured-item-price
                  pa-0
                  black--text
                  size14-weight400
                  mb-4
                "
                data-cy="concierge-featured-single-item-price-rating"
              >
                <template v-if="isPriceVisible">
                  <template v-if="isPriceStartingAt">
                    <p class="product-description">
                      {{ $t('common.price_starting_from') }}
                      {{
                        itemDetails.price_starting_at.value
                          | currency(itemDetails.price_starting_at.baseCurrency)
                      }}
                    </p>
                  </template>

                  <template v-else>
                    <span data-cy="concierge-featured-single-item-price">
                      {{ $t('from') }}
                      {{
                        itemDetails.price_value
                          | currency(itemDetails.price_currency)
                      }}
                      {{ $t('common.per_person') }}
                    </span>
                  </template>
                </template>

                <template v-if="isRatingVisible">
                  <span
                    class="float-right"
                    data-cy="concierge-featured-single-item-rating"
                  >
                    * {{ itemDetails.rating_value }} ({{
                      itemDetails.rating_count
                    }})
                  </span>
                </template>
              </div>
            </template>

            <template v-if="itemDetails.subtitle || itemDetails.description">
              <v-row no-gutters>
                <v-col>
                  <template v-if="itemDetails.subtitle">
                    <vue-markdown
                      class="mb-0"
                      :class="{
                        'mb-3': itemDetails.description,
                      }"
                      data-cy="concierge-featured-single-item-subtitle"
                      :source="itemDetails.subtitle"
                    />
                  </template>

                  <template v-if="itemDetails.description">
                    <vue-markdown
                      class="mb-0"
                      data-cy="concierge-featured-single-item-description"
                      :source="itemDetails.description"
                    />
                  </template>
                </v-col>
              </v-row>
            </template>

            <template v-if="ctaPath">
              <v-btn
                depressed
                color="primary"
                :href="makeResourceUrl(ctaPath)"
                target="_blank"
                @click.stop.prevent="onCTAClick"
                class="mt-3 mt-md-4 view-more-text"
                data-cy="concierge-featured-single-item-cta-link"
              >
                {{ getCtaTextOrCommonLocalizedText }}
              </v-btn>
            </template>

            <template v-if="shouldShowTags">
              <div class="py-2">
                <span
                  class="
                    item-tag
                    px-2
                    py-1
                    mr-1
                    mt-1
                    rounded-pill
                    font-weight-bold
                    d-inline-block
                    text-center
                  "
                  data-cy="concierge-featured-single-item-tag"
                  v-for="(tag, i) in itemDetails.tags"
                  :key="i"
                >
                  {{ tag }}
                </span>
              </div>
            </template>
          </v-col>

          <v-col cols="12" md="6" class="pa-0">
            <v-img
              data-cy="concierge-featured-single-item-image"
              class="item-image"
              :alt="itemDetails.title"
              :src="itemDetails.thumbnail"
            />
          </v-col>
        </v-row>
      </v-card>
    </a>
  </v-container>
</template>

<script>
import ConciergeHomeMixins from '@/modules/concierge/mixins/ConciergeHomeMixins.vue';

export default {
  name: 'ConciergeFeaturedSingleItem',
  mixins: [ConciergeHomeMixins],
  props: {
    rowJustify: String,
    itemDetails: Object,
    ctaPath: String,
    ctaText: String,
  },

  methods: {
    onCardClick() {
      let newPath = this.makeResourceUrl(this.itemDetails.resource);
      this.redirectOrGoToPath(newPath);
    },

    onCTAClick() {
      let newPath = this.makeResourceUrl(this.ctaPath);
      this.redirectOrGoToPath(newPath);
    },
  },

  computed: {
    shouldShowTags() {
      return (
        this.showTagsFF &&
        !!this.itemDetails.tags &&
        this.itemDetails.tags.length > 0
      );
    },

    isRatingVisible() {
      return this.itemDetails.rating_count && this.itemDetails.rating_value;
    },

    isPriceVisible() {
      return !!this.itemDetails.price_value && this.itemDetails.price_currency;
    },

    getCtaTextOrCommonLocalizedText() {
      if (!!this.ctaText) {
        return this.ctaText;
      } else return this.$t('common.find_experience');
    },

    isPriceStartingAt() {
      return (
        this.itemDetails.price_starting_at &&
        parseFloat(this.itemDetails.price_value).toFixed(2) == 0.0
      );
    },
  },
};
</script>

<style scoped lang="scss">
.item-details {
  @media (min-width: map-get($grid-breakpoints, md)) {
    background-color: var(--v-grey1-base);
  }
}

.view-more-text {
  border-radius: 4px !important;
  padding: 9px 12px !important;
  display: inline-block;
  @include font-size(14, 150, 600);
}

.description-text {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-image {
  height: 192px;
  border-radius: 8px;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    height: 300px;
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    border-radius: 0;
    height: 100%;
    min-height: 360px;
  }
}

.item-tag {
  font-size: 12px;
  font-style: normal;
  color: var(--v-primary-base);
  background-color: #e5eefc;
}
::v-deep {
  & .v-responsive__sizer {
    padding-bottom: 0 !important;
  }

  & .v-card {
    @media (max-width: map-get($grid-breakpoints, sm)) {
      border-radius: 0 !important;
    }
  }
}

.featured-item-price {
  @include font-size(14, 21, 400);
}
</style>
