<template>
  <v-card class="trust-card rounded-lg" elevation="0">
    <div
      class="py-8 px-8 px-md-4 px-lg-15 ma-0 align-md-center d-flex flex-column flex-md-row"
    >
      <div class="trust-title" data-cy="trust-component-trust-title">
        <h3 class="size18-weight500">
          {{ trimTitles(catalog.title, 55) }}
        </h3>
      </div>
      <div
        class="trust-list d-flex flex-wrap"
        data-cy="trust-component-trust-list"
        :class="{
          'trust-list-short': catalog.items.length < 3,
        }"
      >
        <div
          v-for="item in catalog.items"
          :key="item.title"
          class="trust-item d-flex align-center"
          data-cy="trust-component-trust-list-item"
        >
          <div class="trust-icon" :data-cy="getIconName(item.category)">
            <simple-svg
              v-if="item.category"
              :src="getIconName(item.category)"
              fill-class-name="fill-to-change"
              stroke-class-name="stroke-to-change"
              fill="var(--v-secondary-base)"
              stroke="var(--v-secondary-base)"
            />
            <simple-svg
              v-else
              :src="require(`@/assets/trust/globe.svg`)"
              fill-class-name="fill-to-change"
              stroke-class-name="stroke-to-change"
              fill="var(--v-secondary-base)"
              stroke="var(--v-secondary-base)"
            />
          </div>
          <p class="trust-message size14-weight400 ma-0 pl-4">
            {{ trimTitles(item.message, 90) }}
          </p>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import ConciergeHomeMixins from '@/modules/concierge/mixins/ConciergeHomeMixins';

export default {
  name: 'ConciergeTrustItem',
  props: {
    catalog: Object,
  },
  mixins: [ConciergeHomeMixins],
  methods: {
    getIconName(cat) {
      try {
        return require(`@/assets/trust/${cat.toLowerCase()}.svg`);
      } catch (e) {
        return require(`@/assets/trust/globe.svg`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.trust {
  &-card {
    width: 100%;
    background-color: var(--v-grey1-base);
  }

  &-title {
    width: 100%;
    flex-shrink: 0;
    margin-bottom: 24px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      width: 25%;
      padding-right: 20px;
      margin-bottom: 0;
    }
  }

  &-list {
    gap: 20px;
    flex-grow: 1;

    &-short {
      justify-content: center;
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      flex-direction: column;

      &-short {
        justify-content: flex-start;
      }
    }
  }

  &-item {
    width: calc(33% - 20px);
    @media (max-width: map-get($grid-breakpoints, md)) {
      width: 100%;
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &-message {
    color: #000000;
  }
}
</style>
