<template>
  <ConciergeFeaturedSingleItem
    v-if="isFeatureOneItem"
    :item-details="catalog.items[0]"
    :cta-path="catalog.call_to_action_path"
    :cta-text="catalog.call_to_action_text"
    :rowJustify="catalog.rowJustify"
  />
  <ConciergeFeaturedMultipleItem
    v-else-if="isFeatureTwoItems"
    :items="multipleItems"
    :title="catalog.title"
    :subtitle="catalog.subtitle"
    :cta-path="catalog.call_to_action_path"
    :cta-text="catalog.call_to_action_text"
    :rowJustify="catalog.rowJustify"
    :hookType="catalog.type"
  />
</template>

<script>
import ConciergeFeaturedSingleItem from './ConciergeFeaturedSingleItem';
import ConciergeFeaturedMultipleItem from './ConciergeFeaturedMultipleItem';

export default {
  name: 'ConciergeFeatureItem',
  props: {
    catalog: {
      type: Object,
    },
  },
  components: { ConciergeFeaturedSingleItem, ConciergeFeaturedMultipleItem },

  computed: {
    multipleItems() {
      return this.catalog.items.slice(0, 2);
    },

    isFeatureOneItem() {
      return this.catalog.display_type === 'feature-one-item';
    },

    isFeatureTwoItems() {
      return this.catalog.display_type === 'feature-two-items';
    },
  },
};
</script>
