<template>
  <v-container class="concierge-container px-0 pt-0" fluid>
    <ConciergeHeaderTitles class="mb-n10" />
    <AddFlightModal v-if="addFlightMode" :isOpen="addFlightMode" />
    <v-container fluid v-if="isLoading">
      <v-row v-for="index in 20" :key="index + 5">
        <v-col v-for="colIndex in skeletonLoaderSize" :key="colIndex + 20">
          <v-skeleton-loader type="card" :tile="false" />
        </v-col>
        <v-col cols="12" class="pt-3">
          <v-skeleton-loader
            class="button-loader"
            height="50"
            width="100%"
            type="button"
            :tile="false"
          />
        </v-col>
        <v-col cols="12" class="pt-10">
          <v-skeleton-loader
            class="mx-auto"
            height="30"
            width="50%"
            type="heading"
            :tile="false"
          />
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-skeleton-loader
            class="mx-auto"
            height="30"
            width="50%"
            type="heading"
            :tile="false"
          />
        </v-col>
      </v-row>
    </v-container>
    <template v-else-if="conciergeGroups">
      <ExploreListComponent class="explore-list-wrapper" />
    </template>
    <template v-else-if="conciergeHookContent.error">
      <v-row class="d-flex justify-center pt-12">
        <v-col cols="auto">
          <v-btn
            class="primary"
            depressed
            large
            data-cy="see-all-city-experiences"
            @click="onSeeAllCityExperiencesClick"
          >
            {{
              $t('discovery.see_all_city_experiences', {
                cityName: this.updatedCityName,
              })
            }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import ConciergeHomeMixins from '@/modules/concierge/mixins/ConciergeHomeMixins.vue';
import ConciergeHeaderTitles from '@/modules/concierge-v3/ConciergeHeaderTitles.vue';
import AddFlightModal from '@/modules/common/components/AddFlightModal.vue';
import FlightRedirectMixins from '@/modules/flights/mixins/FlightRedirectMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import * as Sentry from '@sentry/vue';
import ExploreListComponent from '@/modules/concierge-v3/features/explore-list/ExploreListComponent';

export default {
  name: 'ConciergeHomeComponent',
  components: {
    ExploreListComponent,
    ConciergeHeaderTitles,
    AddFlightModal,
  },
  mixins: [ConciergeHomeMixins, FlightRedirectMixins, GoogleTagManagerMixins],
  data() {
    return {
      addFlightMode: false,
      airlinesCode: {
        EK: 'Emirates',
        FZ: 'flydubai',
      },
    };
  },
  methods: {
    onSeeAllCityExperiencesClick() {
      this.pushButtonClick('See All Experiences', this.cityName);
      if (
        this.$route.query &&
        this.$route.query.city &&
        this.$route.query.lat &&
        this.$route.query.lng
      ) {
        this.$router.push({
          name: 'discoverySearchResults',
          query: {
            city: this.$route.query.city,
            lat: this.$route.query.lat,
            lng: this.$route.query.lng,
          },
        });
      } else {
        this.$router.push({
          name: 'discoverySearchResults',
        });
      }
    },
  },
  computed: {
    updatedCityName() {
      return this.city?.split(',')[0];
    },
    skeletonLoaderSize() {
      let pageSize = 3;
      if (this.$vuetify.breakpoint.smAndDown) {
        pageSize = 2;
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        pageSize = 4;
      }
      return pageSize;
    },
    noCatalogGroups() {
      return this.conciergeGroups && !this.conciergeGroups;
    },
    codeShareInfo() {
      if (
        this.selectedContextFlight?.isCodeShare &&
        this.selectedContextFlight?.codeShares?.length
      ) {
        const codeShareAirline = this.selectedContextFlight?.codeShares[0]
          ?.carrierCode;
        const airline = this.selectedContextFlight.flightNumber.replace(
          /\d+/g,
          ''
        );
        return {
          codeShareAirlineName: this.airlinesCode[codeShareAirline],
          airlineName: this.airlinesCode[airline],
        };
      } else {
        return {};
      }
    },
  },
  created() {
    this.loadConciergeOrDiscover();
  },
  watch: {
    ['$route']: {
      immediate: true,
      handler(val, oldVal) {
        if (!!val && val.query.addFlightModal) {
          this.addFlightMode = true;
        }
        if (!!oldVal && oldVal.query.addFlightModal) {
          this.addFlightMode = false;
        }
        if (oldVal && oldVal.name !== 'add-flight') {
          this.clearRecentlyAddedFlights();
        }
      },
    },
    recentlyAddedFlights: {
      immediate: true,
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.clearRecentlyAddedFlights();
          }, 2000);
        }
      },
    },
    selectedContextFlight: {
      immediate: false,
      handler() {
        this.loadConciergeOrDiscover();
      },
    },
    hasConciergeStagesError(hasConciergeStagesError) {
      if (hasConciergeStagesError) {
        Sentry.captureMessage('Error loading concierge stages', {
          extra: {
            error: hasConciergeStagesError,
          },
        });
        this.$router
          .push({
            name: '500',
          })
          .catch(() => {});
      }
    },
    conciergeCategoriesError(conciergeCategoriesError) {
      if (conciergeCategoriesError) {
        Sentry.setContext('flights', {
          contextFlights: this.contextFlights,
          selectedContextFlight: this.selectedContextFlight,
        });
        Sentry.captureMessage(
          'Error loading concierge categories for this flight'
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-skeleton-loader__heading {
  width: 100%;
}

.button-loader {
  padding: 0;
  width: 100%;
  @media (min-width: map-get($grid-breakpoints, md)) {
    padding: 0 10px;
  }

  ::v-deep .v-skeleton-loader__button {
    width: 100%;
    @media (min-width: map-get($grid-breakpoints, md)) {
      width: 25%;
    }
  }
}

.content-loader {
  padding: 0 16px;
  @media (min-width: map-get($grid-breakpoints, md)) {
    padding: 0 156px;
  }
}

.explore-list-wrapper {
  @media (max-width: map-get($grid-breakpoints, md)) {
    padding-bottom: 90px !important;
  }
}
</style>
