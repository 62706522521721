<template>
  <a
    :href="makeResourceUrl(item.resource)"
    class="text-decoration-none"
    :aria-label="item.title"
  >
    <ProductItemComponent
      :title="item.title"
      :description="item.description"
      :thumbnail="item.thumbnail"
      :badges="item.badges"
      :pointsValue="loyaltyPointsValue"
      :priceValue="productPrice"
      :priceStartingValue="productPriceStartingAt"
      :priceCurrency="item.price_currency"
      :aspectRatio="aspectRatio"
      :hookItemType="hookItemType"
      :height="height"
    />
  </a>
</template>

<script>
import ProductItemComponent from './ProductItemComponent';
import ConciergeHomeMixins from '@/modules/concierge/mixins/ConciergeHomeMixins';

export default {
  name: 'ProductItemContainer',
  mixins: [ConciergeHomeMixins],
  components: { ProductItemComponent },
  props: {
    aspectRatio: {
      type: Number,
      default: 1,
    },

    height: {
      type: Number || String,
      default: null,
    },

    width: {
      type: Number || String,
      default: null,
    },

    item: {
      type: Object,
      default: () => {},
    },

    hookItemType: {
      type: String,
      default: '',
    },
  },

  computed: {
    loyaltyPointsValue() {
      if (this.item && this.item.minimumEarningValue) {
        return this.item.minimumEarningValue.value;
      } else return null;
    },

    productPrice() {
      return this.item.price?.value || Number(this.item.price_value) || 0;
    },

    productPriceStartingAt() {
      return this.item.price_starting_at?.value || 0;
    },
  },
};
</script>

<style></style>
