var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{class:['pa-0', { 'overflow-hidden': _vm.$vuetify.breakpoint.mdAndUp }]},[_c('v-row',{class:[
      'item-card pa-0 pa-sm-10 ma-0 flex-column justify-space-between',
      _vm.rowJustify === 'end' ? 'flex-md-row' : 'flex-md-row-reverse',
    ],attrs:{"data-cy":"featured-multiple-item-item-row","no-gutters":""}},[_c('v-col',{class:[_vm.rowJustify === 'start' ? 'pl-0 pl-md-10' : 'pr-0 pr-md-10'],attrs:{"cols":"12","md":"6"}},[_c('vue-markdown',{staticClass:"featured-item-title",attrs:{"data-cy":"featured-multiple-item-item-title","source":_vm.title,"postrender":_vm.convertFeaturedItemTitle}}),(_vm.subtitle)?[_c('vue-markdown',{class:[_vm.ctaPath ? 'mb-0' : 'mb-3'],attrs:{"data-cy":"featured-multiple-item-item-subtitle","source":_vm.subtitle}})]:_vm._e(),(_vm.ctaPath)?[_c('v-btn',{class:[
            'view-more-text',
            _vm.$vuetify.breakpoint.mdAndDown ? 'mt-1 mb-6' : 'mt-5',
          ],attrs:{"depressed":"","color":"primary","href":_vm.makeResourceUrl(_vm.ctaPath),"data-cy":"featured-multiple-item-item-call-to-action"}},[_vm._v(" "+_vm._s(_vm.getCtaTextOrCommonLocalizedText)+" ")])]:_vm._e()],2),_c('v-col',{staticClass:"item-info-wrapper mx-n3 mx-sm-0",attrs:{"data-cy":"featured-multiple-item-item-wrapper","cols":"12","md":"6"}},[_c('carousel',{staticClass:"item-info-container",attrs:{"data-cy":"carousel-component","scrollPerPage":_vm.$vuetify.breakpoint.lgAndUp,"perPage":_vm.carouselSlideSize,"navigationEnabled":_vm.$vuetify.breakpoint.lgAndUp,"paginationEnabled":false,"touchDrag":_vm.$vuetify.breakpoint.smAndDown,"mouseDrag":_vm.$vuetify.breakpoint.smAndDown,"navigationNextLabel":_vm.navigationNext,"navigationPrevLabel":_vm.navigationPrev}},_vm._l((_vm.items),function(item){return _c('slide',{key:item.title,staticClass:"slide py-0 pl-3 pl-md-0",attrs:{"data-cy":"featured-multiple-item-item-info"}},[_c('ProductItemContainer',{attrs:{"item":item,"hookItemType":_vm.hookType,"aspectRatio":16 / 9,"height":192}})],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }