<template>
  <v-container class="pa-0" data-cy="category-group-container-search">
    <div
      class="search-list d-flex justify-space-between flex-sm-row flex-column"
      data-cy="category-group-container-search-list"
    >
      <div
        data-cy="category-group-container-search-item"
        class="search-item overflow-hidden"
        v-for="item in catalog.items"
        :key="item.title"
      >
        <router-link
          :to="item.query"
          class="
            search-link
            d-flex
            flex-column
            justify-center
            text-decoration-none
          "
          data-cy="category-group-container-search-item-link"
        >
          <v-img
            :alt="`thumbnail for ${item.title}`"
            :src="item.background_image ? item.background_image : placeholder"
            :aspect-ratio="3 / 2"
            class="search-img rounded-lg"
            data-cy="category-group-container-search-item-image"
          />
          <h3
            class="search-text text-center"
            data-cy="category-group-container-search-item-title"
          >
            {{ trimTitles(item.title, 55) }}
          </h3>
          <h4
            class="search-subtext text-center"
            data-cy="category-group-container-search-item-subtitle"
          >
            {{ trimTitles(item.subtitle, 55) }}
          </h4>
        </router-link>
      </div>
    </div>
  </v-container>
</template>

<script>
import ConciergeHomeMixins from '@/modules/concierge/mixins/ConciergeHomeMixins';

export default {
  name: 'ConciergeSearchModule',
  mixins: [ConciergeHomeMixins],
  props: {
    catalog: Object,
  },

  data() {
    return {
      placeholder: require('@/assets/placeholder-thumbnail.png'),
    };
  },
};
</script>

<style scoped lang="scss">
.search {
  &-list {
    gap: 24px;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      gap: 12px;
    }
  }

  &-item {
    border-radius: 8px;
    height: 228px;
    position: relative;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      max-width: calc(50% - 12px);
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  &-link {
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: #000;
      opacity: 0.35;
      z-index: 1;
      border-radius: 8px;
    }
  }

  &-img {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &-text,
  &-subtext {
    color: #fff;
    position: relative;
    z-index: 1;
  }

  &-text {
    @include font-size(24, 36, 700);
  }

  &-subtext {
    @include font-size(14, 21, 700);
  }
}
</style>
