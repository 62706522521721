<template>
  <v-container>
    <v-row>
      <v-col class="pa-0">
        <a :href="info.call_to_action_path">
          <v-img
            :class="$vuetify.breakpoint.mdAndUp ? 'rounded-lg' : ''"
            :alt="
              info.banner_image_alt_text ? info.banner_image_alt_text : 'Banner'
            "
            :src="bannerImage"
          />
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ConciergeBannerItem',
  props: {
    info: {
      type: Object,
      default: null,
    },
  },
  computed: {
    bannerImage() {
      return this.$vuetify.breakpoint.mdAndDown
        ? this.info.banner_image_mobile
        : this.info.banner_image;
    },
  },
};
</script>
